<template>
  <div class="container">
    <div class="info_section">The Old Bakehouse</div>

    <v-text-field label="Auth key" v-model="authKey"></v-text-field>
    <v-btn primary class="tuya_btn" variant="text" @click="getAccessToken()">{{
      buttonText
    }}</v-btn>
  </div>
</template>

<script>
// import Axios from "axios";

export default {
  name: "Home",

  data() {
    return {
      buttonText: "Start",
      accessToken: "",
      authKey: "",
      url: "",
      message: "",
      urlheaders: { "Content-Type": "application/json", withCredentials: true },
      backendurl: process.env.VUE_APP_API,
      baseUrl: "https://openapi.tuyaeu.com",
    };
  },

  methods: {
    getAccessToken() {
      this.$router.push({ path: "/octopus" });
      this.$store.commit("setAuthKey", this.authKey);

      this.buttonText = "Wait";

      // this.action = "auth";
      // this.stringToSignUrl = "/v1.0/token?grant_type=1";
      // var postdata = {
      //   module: "tuya",
      //   action: "auth",
      //   key: this.authKey,
      //   detail: {
      //     baseUrl: this.baseUrl,
      //     clientId: this.clientId,
      //     clientSecret: this.clientSecret,
      //     stringToSignUrl: this.stringToSignUrl,
      //   },
      // };

      // Axios.post(this.backendurl, postdata, this.headers).then((res) => {
      //   if (res.data) {
      //     this.$store.commit("setAccessToken", res.data.result.access_token);
      //     this.$router.push({ path: "/octopus" });
      //   }
      // });
    },
  },
};
</script>

<style scoped>
.container {
  margin: 20px;
  text-align: center;
}
.info_section {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
