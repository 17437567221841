<template>
  <div class="container">
    <NavBar />
    <div class="page_title">Log</div>

    <div class="time_table">
      <v-data-table
        height="60vh"
        fixed-header
        :headers="fields"
        :items="logDetails"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "Log",
  components: { NavBar, VDataTable },
  data() {
    return {
      logDetails: [],
      fields: [
        { key: "deviceName", title: "Device", sortable: false },
        { key: "actionTime", title: "Time", sortable: true },
        { key: "action", title: "Action", sortable: true },
        { key: "tariff", title: "Tariff", sortable: true },
      ],
      backendurl: process.env.VUE_APP_API,
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.logDetails = [];
      var postdata = {
        module: "settings",
        action: "allLogs",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          this.logDetails = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
</style>
