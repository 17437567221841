<template>
  <nav>
    <div>
      <v-btn
        class="nav_button"
        size="x-small"
        variant="text"
        :to="{ name: 'Octopus_API' }"
        >Octopus</v-btn
      >
    </div>
    <div>
      <v-btn
        class="nav_button"
        size="x-small"
        variant="text"
        :to="{ name: 'Settings' }"
        >Settings</v-btn
      >
    </div>
    <div>
      <v-btn
        class="nav_button"
        size="x-small"
        variant="text"
        :to="{ name: 'Tuya_API' }"
        >Tuya</v-btn
      >
    </div>
    <div>
      <v-btn
        class="nav_button"
        size="x-small"
        variant="text"
        :to="{ name: 'Log' }"
        >Log</v-btn
      >
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
nav {
  display: flex;
  gap: 20px;
  background-color: rgb(179, 175, 252);
  border-bottom: 1px rgb(179, 175, 252);
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .nav_button {
    font-size: 10px;
  }
}
</style>
