<template>
  <div class="container">
    <NavBar />

    <div class="page_title">Octopus Tariff Tickler</div>

    <v-select
      v-model="currentTariffCode"
      label="Choose a tariff"
      :items="codes"
      item-title="text"
      item-value="value"
      variant="outlined"
      @update:modelValue="setTariff()"
    ></v-select>

    {{ message }}
    <div v-if="loaded">
      <v-data-table
        v-model:items-per-page="itemsPerPage"
        fixed-header
        height="700"
        :headers="headers"
        :items="tariffResponse"
        @click:row="loadPrice"
      >
        <template v-slot:item.reviewLink="{ item }">
          <span>
            <div :class="{ style_low: item.selectable.value_exc_vat == 1 }">
              {{ item.selectable.value_exc_vat }}
            </div>
          </span>
        </template>
        <template v-slot:item.lowest="{ item }">
          <span>
            <div
              v-if="item.selectable.lowest == 1"
              :class="getBackGround(item)"
            >
              {{ `${getAdvice(item.selectable.value_exc_vat)}` }}
            </div>
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "Octopus_API",
  components: {
    NavBar,
    VDataTable,
  },
  data() {
    return {
      message: "",
      loaded: false,
      itemsPerPage: 100,
      headers: [
        { key: "valid_from", title: "From", sortable: true },
        { key: "value_exc_vat", title: "Exc vat", sortable: true },
        { key: "value_inc_vat", title: "Inc vat", sortable: true },
        { key: "lowest", title: "Good Value", sortable: true, align: "center" },
      ],
      currentTariffCode: "AGILE-24-04-03",
      url: "",
      urlheaders: { "Content-Type": "application/json", withCredentials: true },
      productResponse: [],
      codes: [],
      tariffResponse: [],
    };
  },
  created() {
    this.octopusApiProducts();
    this.octopusApiTariffs();
  },
  methods: {
    getBackGround(item) {
      if (item.raw.lowest == 1) {
        return "style_low";
      }
    },
    getAdvice(price) {
      if (price > 15) {
        return "Just in the lower range ";
      }
      if (price > 10 && price <= 15) {
        return "Not too bad";
      }
      if (price > 5 && price <= 10) {
        return "Looking good";
      }
      if (price > 0 && price <= 5) {
        return "Wowzers";
      }
      if (price <= 0) {
        return "Fill yer boots";
      }
    },
    octopusApiProducts() {
      this.url = "https://api.octopus.energy/v1/products/";
      Axios.get(this.url).then((response) => {
        let productArray = response.data.results;
        for (let i = 0; i < productArray.length; i++) {
          let newCode = {
            value: productArray[i].code,
            text: productArray[i].code + ": " + productArray[i].description,
          };
          this.codes.push(newCode);
        }
        // this.productResponse = productArray;
      });
    },
    setTariff() {
      this.loaded = false;
      this.octopusApiTariffs();
    },
    octopusApiTariffs() {
      this.tariffResponse = [];
      this.url =
        "https://api.octopus.energy/v1/products/" +
        this.currentTariffCode +
        "/electricity-tariffs/E-1R-" +
        this.currentTariffCode +
        "-N/standard-unit-rates/";
      Axios.get(this.url).then((response) => {
        let tariffArray = response.data.results;
        const currentDate = new Date();
        let lowest = 100;
        for (let i = tariffArray.length - 1; i > 0; i--) {
          const dateFrom = tariffArray[i].valid_from;
          const dateTo = tariffArray[i].valid_to;

          const inputDateAsDateFrom = new Date(dateFrom); // Converts to BSM
          const inputDateFrom = inputDateAsDateFrom
            .toLocaleString()
            .split(",")[1];

          const inputDateAsDateTo = new Date(dateTo); // Converts to BSM
          const inputDateTo = inputDateAsDateTo.toLocaleString().split(",")[1];

          const formattedDateFromTime = inputDateFrom;
          const formattedDateToTime = inputDateTo;

          var item = {
            valid_from: formattedDateFromTime + " - " + formattedDateToTime,
            valid_to: formattedDateToTime,
            value_exc_vat: tariffArray[i].value_exc_vat.toFixed(2),
            value_inc_vat: tariffArray[i].value_inc_vat.toFixed(2),
          };

          if (inputDateAsDateTo >= currentDate) {
            this.tariffResponse.push(item);
            if (tariffArray[i].value_exc_vat < lowest) {
              lowest = tariffArray[i].value_exc_vat;
            }
          }
        }

        for (let i = 0; i < this.tariffResponse.length; i++) {
          this.tariffResponse[i].id = i + 1;
          if (Number(this.tariffResponse[i].value_exc_vat) - 3 <= lowest) {
            this.tariffResponse[i].lowest = 1;
          } else {
            this.tariffResponse[i].lowest = 0;
          }
        }

        // this.tariffResponse.sort((a, b) => a.valid_from > b.valid_from);
        if (tariffArray.length > 0 && this.tariffResponse.length == 0) {
          this.message = "No future date data";
        } else {
          this.message = "";
          this.loaded = true;
        }
      });
    },
    loadPrice(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
.style_low {
  background-color: lightgreen;
}
.style_default {
  background-color: red;
}
</style>
