import { createRouter, createWebHistory } from "vue-router";
import Octopus from "../components/Octopus.vue";
import Settings from "../components/Settings.vue";
import Tuya from "../components/Tuya.vue";
import Home from "../components/Home.vue";
import Log from "../components/Log.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/octopus",
    name: "Octopus_API",
    component: Octopus,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/tuya",
    name: "Tuya_API",
    component: Tuya,
  },
  {
    path: "/log",
    name: "Log",
    component: Log,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
