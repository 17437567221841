import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    accessToken: null,
    authKey: null,
  },
  mutations: {
    setAuthKey(state, authKey) {
      state.authKey = authKey;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
  },
  actions: {},
  getters: {},
  modules: {},
  plugins: [createPersistedState()],
});
